import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 0;
`;

export const Icon = styled(FontAwesomeIcon)`
  height: 45px;
  width: 46px;
  color: #ccc;
  font-size: 40px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
`;

export const Description = styled.div`
  color: #A4A4A4;
  font-family: "Open Sans";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  padding-left: 12px;
`;
