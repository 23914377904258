import styled from 'styled-components';
import PhoneInput from 'react-phone-number-input/input';

const breakPoint = '600px';

export const Heading = styled.h3`
  color: #4D4D4D;
  font-family: "Helvetica Neue", "Open Sans";
  font-size: 36px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 46px;
  text-align: center;
  margin: 45px 0;
`;

export const PanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 600px;
  margin: 0 auto;

  @media (min-width: ${breakPoint}) {
    & {
      flex-direction: row;
    }
  }
`;

export const Panel = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10px;
  flex-direction: column;

  @media (min-width: ${breakPoint}) {
    & {
      width: 50%;
    }
  }
`;

export const FieldLabel = styled.label`
  color: #A4A4A4;
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 15px;
`;

export const Input = styled(PhoneInput)`
  height: 40px;
  width: 360px;
  border: 1px solid #CCCCCC;
  border-radius: 2px;
  background-color: #FFFFFF;
  padding: 0 10px;
  box-sizing: border-box;
  font-size: 16px;
  font-family: "Open Sans";
  margin: 10px 0;
  outline-color: #6e418d;
`;

export const Dot = styled.span`
  color: #F82626;
`;
