import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { h, Component } from 'preact';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import { setCurrentStep } from '../../../../actions/steps';
import { stepIds }from '../../../../consts/steps';

import { Button, Icon } from './CloseButton.styles';

class CloseButton extends Component {
  onClick = () => {
    const { currentStepId } = this.props;

    if (currentStepId === stepIds.QUIT_BOOKING) {
      this.props.setCurrentStep(null);
    } else {
      this.props.setCurrentStep(stepIds.QUIT_BOOKING);
    }
  };

  render() {
    return (
      <Button onClick={this.onClick}>
        <Icon icon={faTimes} />
      </Button>
    );
  }
};

CloseButton.propTypes = {
  setCurrentStep: PropTypes.func.isRequired,
  currentStepId: PropTypes.string,
};

export default connect(null, {
  setCurrentStep,
})(CloseButton);
