import PropTypes from 'prop-types';
import { faRedo } from '@fortawesome/free-solid-svg-icons';

import { Button, Icon } from './StartOverButton.styles';

const StartOverButton = ({ onClick }) => {
  return (
    <Button onClick={onClick}>
      <Icon icon={faRedo} />
      Start Over
    </Button>
  );
};

StartOverButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default StartOverButton;
