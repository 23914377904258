import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Icon = styled(FontAwesomeIcon)`
  margin-right: 5px;
`;

export const Button = styled.button`
  color: ${props => props.active ? '#fff' : '#4D4D4D'};
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 52px;
  text-align: center;
  border: none;
  outline: none;
  cursor: pointer;
  background: ${props => props.active ? '#3F9A0D' : 'none'};
  border-radius: 4px;
  padding: 0px 10px;
`;
