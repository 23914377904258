import { h, Component } from 'preact';
import { faShieldAlt } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import { connect }from'react-redux';
import { isPossiblePhoneNumber } from 'react-phone-number-input';

import { sendOtp } from '../../actions/otp';
import ModalFooter from '../ModalFooter';

import { Heading, PanelContainer, Panel, FieldLabel, Input, Dot } from './SendOtpStep.styles';
import phoneSvg from './phone.svg';
import Notice from './components/Notice';

class SendOtpStep extends Component {
  state = {
    phoneNumber: this.props.phoneNumber || '',
  };

  setPhoneNumber = phoneNumber => {
    this.setState({ phoneNumber });
  };

  goNext = () => {
    const { phoneNumber } = this.state;
    this.props.sendOtp(phoneNumber);
    this.props.nextStep();
  };

  render() {
    const { phoneNumber } = this.state;
    const isPhoneNumberValid = isPossiblePhoneNumber(phoneNumber);

    return (
      <div>
        <Heading>
          We need your cellphone number <br /> to send you an OTP
        </Heading>
        <PanelContainer>
          <Panel>
            <img src={phoneSvg} />
          </Panel>
          <Panel>
            <FieldLabel>
              Cellphone (for booking purposes) <Dot>*</Dot>
            </FieldLabel>
            <Input onChange={this.setPhoneNumber} value={phoneNumber} />
            <Notice icon={faShieldAlt}>
              Your details will not be shared with any outside parties and will be used solely for the purposes of booking your appointments
            </Notice>
          </Panel>
        </PanelContainer>
        <ModalFooter
          isNextButtonAvailable={isPhoneNumberValid}
          onNextButtonClick={this.goNext}
          backButtonClosesModal={true}
        />
      </div>
    );
  };
};

SendOtpStep.propTypes = {
  nextStep: PropTypes.func.isRequired,
  sendOtp: PropTypes.func.isRequired,
  phoneNumber: PropTypes.string,
};

export default connect(state => ({
  phoneNumber: state.otp.phoneNumber,
}), { sendOtp })(SendOtpStep);
