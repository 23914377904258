import styled from 'styled-components';

const breakPoint = '600px';

export const Footer = styled.div`
  background-color: #F5F5F5;
  padding: 10px 20px;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${breakPoint}) {
    & {
      flex-direction: row;
    }
  }
`;

export const FooterLeftColumn = styled.div`
  display: flex;
  justify-content: center;
  padding: 5px;

  @media (min-width: ${breakPoint}) {
    & {
      width: 20%;
      justify-content: flex-start;
    }
  }
`;

export const FooterMiddleColumn = styled.div`
  width: 60%;
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 5px;
`;

export const FooterRightColumn = styled.div`
  justify-content: center;
  display: flex;
  padding: 5px;

  @media (min-width: ${breakPoint}) {
    & {
      width: 20%;
      justify-content: flex-end;
    }
  }
`;
