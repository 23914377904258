export const SET_CURRENT_STEP = 'SET_CURRENT_STEP';
export const GO_TO_PREV_STEP = 'GO_TO_PREV_STEP';

export const setCurrentStep = (stepId) => ({
  type: SET_CURRENT_STEP,
  payload: stepId,
});

export const goToPrevStep = () => ({
  type: GO_TO_PREV_STEP,
});
