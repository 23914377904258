const breakPoint = 600;

export const modalStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: window.innerWidth >= breakPoint ? 'center' : 'initial',
  },
  content: {
    position: 'relative',
    width: '100%',
    maxWidth: '920px',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    border: '1px solid #ccc',
    background: '#fff',
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
    borderRadius: '12px',
    outline: 'none',
    padding: '0'
  }
};
