import { GET_PRACTICE_DATA_SUCCESS } from '../actions/practice';

export default (state = {}, action) => {
  switch (action.type) {
    case GET_PRACTICE_DATA_SUCCESS:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};
