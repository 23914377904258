import PropTypes from 'prop-types';

import { Button } from './CancelButton.styles';

const CancelButton = ({ label, onClick }) => {
  return (
    <Button onClick={onClick}>
      { label }
    </Button>
  );
};

CancelButton.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default CancelButton;
