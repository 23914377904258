import { SEND_OTP } from '../actions/otp';

const initialState = {
  phoneNumber: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SEND_OTP:
      return {
        ...state,
        phoneNumber: action.payload,
      };
    default:
      return state;
  }
};
