import RawCalendar from 'react-calendar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import PropTypes from  'prop-types';

import { Container } from './Calendar.styles';
import NavigationLabel from './components/NavigationLabel';
import createTileContent from './components/TileContent';

const createIsDateDisabled = ({ availableDates }) => ({ date }) => {
  return !availableDates.filter(x => moment(x).isSame(moment(date))).length;
};

const Calendar = ({ availableDates, onDateSelect, defaultSelectedDate }) => {
  const minDate = moment().add(2, 'days').hour(0).minute(0).second(0).millisecond(0).toDate();
  const maxDate = moment().add(2, 'months').toDate();

  const isDateDisabled = createIsDateDisabled({ availableDates });
  const TileContent = createTileContent({ isDateDisabled });

  return (
    <Container>
      <RawCalendar
        view="month"
        prevLabel={<FontAwesomeIcon icon={faChevronLeft} />}
        nextLabel={<FontAwesomeIcon icon={faChevronRight} />}
        tileDisabled={isDateDisabled}
        navigationLabel={NavigationLabel}
        minDate={minDate}
        maxDate={maxDate}
        tileContent={TileContent}
        onClickDay={onDateSelect}
        defaultValue={defaultSelectedDate}
      />
      <ReactTooltip />
    </Container>
  );
};

Calendar.propTypes = {
  availableDates: PropTypes.arrayOf(
    PropTypes.instanceOf(Date)
  ).isRequired,
  onDateSelect: PropTypes.func.isRequired,
  defaultSelectedDate: PropTypes.instanceOf(Date),
};

export default Calendar;
