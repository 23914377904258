import { h, Component } from 'preact';
import { faClock, faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Calendar from '../Calendar';
import { selectDate } from '../../actions/calendar';
import { setCurrentStep } from '../../actions/steps';
import ContactUs from '../ContactUs';
import { stepIds } from '../../consts/steps';

import { Container, Heading, Description, Bold, Panel } from './StartScreen.styles';
import Info from './components/Info';
import CallToAction from './components/CallToAction';


class StartScreen extends Component {
  onDateSelect = date => {
    this.props.selectDate(date);
    this.props.setCurrentStep(stepIds.SEND_OTP);
  };

  render() {
    const {
      addressLine1,
      addressLine2,
      addressLine3,
      availableDates,
    } = this.props;

    return (
      <Container>
        <Panel>
          <Heading>
            Book an appointment online
          </Heading>
          <Description>
            Days with available appointments are shown {' '}
            <Bold>in green</Bold>. You can book appointments online up to 2 months in advance. 
            Please call us if you need to book more than two months ahead.
          </Description>
          <Info icon={faClock}>
            Comprehensive Eye Health Examination <br />
            (60 minutes)
          </Info>
          <Info icon={faMapMarkedAlt}>
            {addressLine1 && <div>{addressLine1}</div>}
            {addressLine2 && <div>{addressLine2}</div>}
            {addressLine3}
          </Info>

          <CallToAction />

          <ContactUs />
        </Panel>
        <Panel>
          <Calendar availableDates={availableDates} onDateSelect={this.onDateSelect} />
        </Panel>
      </Container>
    );
  };
};

StartScreen.propTypes = {
  addressLine1: PropTypes.string,
  addressLine2: PropTypes.string,
  addressLine3: PropTypes.string,
  availableDates: PropTypes.arrayOf(
    PropTypes.instanceOf(Date)
  ).isRequired,
  selectDate: PropTypes.func.isRequired,
  setCurrentStep: PropTypes.func.isRequired,
};

export default connect(state => {
  const { addressLine1, addressLine2, addressLine3 } = state.practice;
  const { availableDates } = state.calendar;
  
  return {
    addressLine1,
    addressLine2,
    addressLine3,
    availableDates,
  };
}, {
  selectDate,
  setCurrentStep,
})(StartScreen);
