import { Component } from 'preact';
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';

import Calendar from '../Calendar';
import ModalFooter from '../ModalFooter';
import { getAvailableTimeSlots, selectTimeSlot, selectDate } from '../../actions/calendar';

import { Heading, Panel, PanelContainer, TimeOfDayHeading, TimeSlotsContainer, NoFreeSpotsText, NoFreeSpotsHeading, NoFreeSpotsContainer } from './SelectTimeSlotStep.styles';
import TimeSlot from './components/TimeSlot';
import ContactUs from './components/ContactUs';

class SelectTimeSlotStep extends Component {
  constructor(props) {
    super(props);
    const { getAvailableTimeSlots, selectedDate } = this.props;

    getAvailableTimeSlots(selectedDate);
  }

  get availableTimeSlots() {
    const noon = moment().set('h', 12).set('m', 0).set('s', 0).set('ms', 0);

    return this.props.availableTimeSlots.reduce((prevValue, timeSlot) => {
      const [h, m, s] = timeSlot.split(':');
      const currentSlot = moment().set('h', h).set('m', m).set('s', s);

      if (currentSlot.isBefore(noon)) {
        return  {
          ...prevValue,
          morning: [...prevValue.morning, timeSlot]
        };
      }

      return  {
        ...prevValue,
        afternoon: [...prevValue.afternoon, timeSlot]
      };
    }, {
      morning: [],
      afternoon: [],
    });
  }

  selectDate = date => {
    const { selectDate, getAvailableTimeSlots } = this.props;

    selectDate(date);
    getAvailableTimeSlots(date);
  };

  render() {
    const { availableDates, selectTimeSlot, selectedDate, selectedTimeSlot } = this.props;
    const availableTimeSlots = this.availableTimeSlots;
    const areTimeSlotsAvailable = Boolean(availableTimeSlots.morning.length || availableTimeSlots.afternoon.length);

    return (
      <div>
        <Heading>
          Select appointment date and time
        </Heading>
        <PanelContainer withPadding>
          <Panel width="60%">
            <Calendar
              availableDates={availableDates}
              onDateSelect={this.selectDate}
              defaultSelectedDate={selectedDate}
            />
          </Panel>
          <Panel width="40%" justifyContent="space-between">
            {areTimeSlotsAvailable ? (
              <>
                <TimeSlotsContainer>
                  <PanelContainer>
                    <Panel>
                      <TimeOfDayHeading>Morning</TimeOfDayHeading>
                      {availableTimeSlots.morning.map(timeSlot => (
                        <TimeSlot
                      key={timeSlot}
                      timeSlot={timeSlot}
                      onClick={selectTimeSlot}
                      active={selectedTimeSlot === timeSlot}
                    />
                  ))}
                    </Panel>
                    <Panel>
                      <TimeOfDayHeading>Afternoon</TimeOfDayHeading>
                      {availableTimeSlots.afternoon.map(timeSlot => (
                        <TimeSlot
                      key={timeSlot}
                      timeSlot={timeSlot}
                      onClick={selectTimeSlot}
                      active={selectedTimeSlot === timeSlot}
                    />
                  ))}
                    </Panel>
                  </PanelContainer>
                </TimeSlotsContainer>
                <ContactUs />
              </>
                ) : (
                  <NoFreeSpotsContainer>
                    <NoFreeSpotsHeading>No free spots to select</NoFreeSpotsHeading>
                    <NoFreeSpotsText>Please contact practice for arrange booking.</NoFreeSpotsText>
                  </NoFreeSpotsContainer>
                )}
          </Panel>
        </PanelContainer>
        <ModalFooter
          isNextButtonAvailable={Boolean(selectedDate && selectedTimeSlot)}
          onNextButtonClick={this.props.nextStep}
          backButtonClosesModal
        />
      </div>
    );
  }
}

SelectTimeSlotStep.propTypes = {
  nextStep: PropTypes.func.isRequired,
  availableDates: PropTypes.arrayOf(PropTypes.instanceOf(Date)).isRequired,
  availableTimeSlots: PropTypes.arrayOf(PropTypes.string).isRequired,
  getAvailableTimeSlots: PropTypes.func.isRequired,
  selectTimeSlot: PropTypes.func.isRequired,
  selectDate: PropTypes.func.isRequired,
  selectedDate: PropTypes.instanceOf(Date),
  selectedTimeSlot: PropTypes.oneOfType([PropTypes.string, null]).isRequired,
};

export default connect(state => {
  const { availableDates, availableTimeSlots, selectedDate, selectedTimeSlot } = state.calendar;
  
  return {
    availableDates,
    availableTimeSlots,
    selectedDate,
    selectedTimeSlot,
  };
}, {
  selectDate,
  getAvailableTimeSlots,
  selectTimeSlot,
})(SelectTimeSlotStep);
