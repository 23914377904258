import moment from 'moment';

import { callApi } from './app';

export const GET_AVAILABLE_DATES = 'GET_AVAILABLE_DATES';
export const GET_AVAILABLE_DATES_SUCCESS = 'GET_AVAILABLE_DATES_SUCCESS';
export const GET_AVAILABLE_TIME_SLOTS = 'GET_AVAILABLE_TIME_SLOTS';
export const GET_AVAILABLE_TIME_SLOTS_SUCCESS = 'GET_AVAILABLE_TIME_SLOTS_SUCCESS';

export const SELECT_DATE = 'SELECT_DATE';
export const SELECT_TIME_SLOT = 'SELECT_TIME_SLOT';

export const getAvailableDates = () => async (dispatch) => {
  dispatch({
    type: GET_AVAILABLE_DATES,
  });

  const { data } = await dispatch(
    callApi({
      url: '/api/v1/booking/options',
      method: 'GET',
    })
  );

  const availableDates = data.map(d => moment(d).set('hour', 0).toDate());

  dispatch({
    type: GET_AVAILABLE_DATES_SUCCESS,
    payload: availableDates
  });
};

export const getAvailableTimeSlots = date => async (dispatch) => {
  dispatch({
    type: GET_AVAILABLE_TIME_SLOTS,
  });

  date = moment(date).format('Y-MM-DD');

  const { data } = await dispatch(
    callApi({
      url: `/api/v1/booking/options/${date}`,
      method: 'GET',
    })
  );

  dispatch({
    type: GET_AVAILABLE_TIME_SLOTS_SUCCESS,
    payload: data
  });
};

export const selectDate = date => ({
  type: SELECT_DATE,
  payload: date
});

export const selectTimeSlot = timeSlot => ({
  type: SELECT_TIME_SLOT,
  payload: timeSlot
});
