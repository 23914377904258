import PropTypes from 'prop-types';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

import { Button, Icon } from './BackButton.styles';

const BackButton = ({ onClick }) => {
  return (
    <Button onClick={onClick}>
      <Icon icon={faChevronLeft} />
      Go Back
    </Button>
  );
};

BackButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default BackButton;
