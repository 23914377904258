
import styled from 'styled-components';

export const Container = styled.div`
  color: #757575;
  font-family: "Open Sans";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
`;

export const Link = styled.a`
 text-decoration: none;
 color: #7A1AC0;
`;
