export const GET_PRACTICE_DATA = 'GET_PRACTICE_DATA';
export const GET_PRACTICE_DATA_SUCCESS = 'GET_PRACTICE_DATA_SUCCESS';

// TODO: switch to API data
export const getPracticeData = () => async (dispatch) => {
  dispatch({
    type: GET_PRACTICE_DATA,
  });

  const practiceData = {
    addressLine1: '16 The Costsworlds',
    addressLine2: 'Malawa Road',
    addressLine3: 'Sunninghill 219',
    email: 'some@email.com',
  };

  dispatch({
    type: GET_PRACTICE_DATA_SUCCESS,
    payload: practiceData
  });
};
