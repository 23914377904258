import PropTypes from  'prop-types';
import { h, Component } from 'preact';
import { connect } from 'react-redux';

import ContactUs from '../ContactUs';
import { setCurrentStep } from '../../actions/steps';
import { stepIds } from '../../consts/steps';

import { Footer, FooterLeftColumn, FooterMiddleColumn, FooterRightColumn } from './ModalFooter.styles';
import StartOverButton from './components/StartOverButton';
import BackButton from './components/BackButton';
import NextButton from './components/NextButton';

class ModalFooter extends Component {
  onBackButtonClick = () => {
    const { onBackButtonClick } = this.props;

    if (onBackButtonClick) {
      onBackButtonClick();
    }
  };

  onStartOverClick = () => {
    const { onBackButtonClick, setCurrentStep } = this.props;

    if (onBackButtonClick) {
      onBackButtonClick();
    }

    setCurrentStep(stepIds.QUIT_BOOKING);
  };

  render() {
    const { isNextButtonAvailable, onNextButtonClick, backButtonClosesModal } = this.props;
     
    return (
      <Footer>
        <FooterLeftColumn>
          {backButtonClosesModal
            ? <StartOverButton onClick={this.onStartOverClick} />
            : <BackButton onClick={this.onBackButtonClick} />
          }
        </FooterLeftColumn>
        <FooterMiddleColumn>
          <ContactUs />
        </FooterMiddleColumn>
        <FooterRightColumn>
          {isNextButtonAvailable && (
            <NextButton onClick={onNextButtonClick} />
          )}
        </FooterRightColumn>
      </Footer>
    );
  };
};

ModalFooter.propTypes = {
  isNextButtonAvailable: PropTypes.bool.isRequired,
  onNextButtonClick: PropTypes.func.isRequired,
  onBackButtonClick: PropTypes.func,
  setCurrentStep: PropTypes.func.isRequired,
  backButtonClosesModal: PropTypes.bool,
};

ModalFooter.defaultProps = {
  backButtonClosesModal: false,
};

export default connect(null, {
  setCurrentStep,
})(ModalFooter);
