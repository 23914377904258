import { GET_AVAILABLE_DATES_SUCCESS, SELECT_DATE, GET_AVAILABLE_TIME_SLOTS_SUCCESS, SELECT_TIME_SLOT } from '../actions/calendar';

const initialState = {
  availableDates: [],
  availableTimeSlots: [],
  selectedDate: null,
  selectedTimeSlot: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_AVAILABLE_DATES_SUCCESS: {
      return {
        ...state,
        availableDates: action.payload,
      };
    }
    case SELECT_DATE: {
      return {
        ...state,
        selectedDate: action.payload,
        selectedTimeSlot: null
      };
    }
    case GET_AVAILABLE_TIME_SLOTS_SUCCESS: {
      return {
        ...state,
        availableTimeSlots: action.payload,
      };
    }
    case SELECT_TIME_SLOT: {
      return {
        ...state,
        selectedTimeSlot: action.payload
      };
    }
    default:
      return state;
  }
};
