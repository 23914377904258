import PropTypes from 'prop-types';

import { Button } from './ConfirmButton.styles';

const ConfirmButton = ({ label, onClick }) => {
  return (
    <Button onClick={onClick}>
      {label}
    </Button>
  );
};

ConfirmButton.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ConfirmButton;
