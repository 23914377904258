
import { h, Component } from 'preact';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import StartScreen from '../StartScreen';
import StepsModal from '../StepsModal';
import { Container } from '../../shared/Styles';
import { init } from '../../actions/app';

import { Wrapper } from './App.styles';
import './App.css';

class App extends Component {
	constructor(props) {
		super(props);
		this.props.init();
	}

	render() {
		return (
  <Wrapper>
    <Container>
      <StartScreen />
      <StepsModal />
    </Container>
  </Wrapper>
		);
	}
}

App.propTypes = {
	init: PropTypes.func.isRequired,
};

export default connect(null, {
	init
})(App);
