import PropTypes from 'prop-types';

import { Content } from './TileContent.styles';

const createTileContent = ({ isDateDisabled }) => {
  const TileContent = ({ date }) => {
    const dataTip = !isDateDisabled({ date }) ? 'Available appointments' : null;
    return <Content data-tip={dataTip}>{date.getDate()}</Content>;
  };

  TileContent.propTypes = {
    date: PropTypes.instanceOf(Date).isRequired,
  };

  return TileContent;
};

export default createTileContent;
