import styled from 'styled-components';

export const Button = styled.button`
  box-sizing: border-box;
  height: 50px;
  width: 130px;
  border: 1px solid #7A1AC0;
  border-radius: 5px;
  background: none;
  outline: none;
  color: #6E418E;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
`;
