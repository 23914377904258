import PropTypes from 'prop-types';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

import { Button, Icon } from './NextButton.styles';

const NextButton = ({ onClick }) => {
  return (
    <Button onClick={onClick}>
      Next
      <Icon icon={faChevronRight} />
    </Button>
  );
};

NextButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default NextButton;
