import { h, render } from 'preact';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import Modal from 'react-modal';

import App from './components/App';
import reducers from './reducers';

const mountPointId = 'optimax-booking-widget';
let mountPoint = document.getElementById(mountPointId);

const isDevEnv = process.env.NODE_ENV === 'development';

if (isDevEnv && !mountPoint) {
  mountPoint = document.createElement('div');
  mountPoint.id = mountPointId;
  document.body.appendChild(mountPoint);
}

Modal.setAppElement(mountPoint);

export const store = createStore(reducers, applyMiddleware(thunk, logger));

const renderApp = () => {
  if (!mountPoint) {
    // eslint-disable-next-line no-console
    console.warn(`No booking widget mount point found. Place '<div id="${mountPointId}"></div>' in the html`);
    return;
  }

  render(
    <Provider store={store}>
      <App />
    </Provider>
  , mountPoint);
};

export default renderApp;

if (module.hot) {
  // Enable Webpack hot module replacement for reducers
  module.hot.accept('./components/App', () => {
    const newReducer = reducers;
    store.replaceReducer(newReducer);
    renderApp();
  });
}

