import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import RawLoader from 'react-loader-spinner';

import { Container } from './Loader.styles';

const Loader = () => {
  return (
    <Container>
      <RawLoader
        type="Oval"
        color="#6E418E"
        height={100}
        width={100}
      />
    </Container>
  );
};

export default Loader;
