import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Container, Link } from './ContactUs.styles';

const ContactUs = ({ practiceEmail }) => {
  return (
    <Container>
      <Link href={`mailto:${practiceEmail}`}>Contact us</Link> if you have any trouble making a booking.
    </Container>
  );
};

ContactUs.propTypes = {
  practiceEmail: PropTypes.string.isRequired
};

export default connect(state => ({
  practiceEmail: state.practice.email,
}))(ContactUs);
