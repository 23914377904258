import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 0;
`;

export const Icon = styled(FontAwesomeIcon)`
  height: 48px;
  width: 20px;
  color: #7A1AC0;
  font-family: "Font Awesome 5 Pro";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  padding-right: 10px;
`;

export const Description = styled.div`
  color: #757575;
  font-family: "Open Sans";
  font-size: 18px;
  letter-spacing: 0;
  line-height: 24px;
`;
