import styled from 'styled-components';

export const Link = styled.a`
  text-decoration: none;
  color: #7A1AC0;
`;

export const Text = styled.span`
  color: #757575;
  font-family: "Open Sans";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
`;
