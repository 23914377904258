import styled from 'styled-components';

const breakPoint = '600px';

export const Heading = styled.h3`
  color: #4D4D4D;
  font-family: "Helvetica Neue", "Open Sans";
  font-size: 36px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 36px;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 78px;
`;

export const PanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: ${props => props.withPadding ? '0 50px' : 0};

  @media (min-width: ${breakPoint}) {
    & {
      flex-direction: row;
      margin: 0;
    }
  }
`;

export const Panel = styled.div`
  width: 100%;
  display: flex;
  padding: 10px;
  flex-direction: column;
  justify-content: ${props => props.justifyContent || 'initial'};

  @media (min-width: ${breakPoint}) {
    & {
      width: ${props => props.width || '50%'};
    }
  }
`;

export const TimeSlotsContainer = styled.div`
  margin-top: 50px;
`;

export const TimeOfDayHeading = styled.div`
  color: #4D4D4D;
  font-family: "Open Sans";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  margin-bottom: 14px;
`;

export const NoFreeSpotsContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const NoFreeSpotsText = styled.div`
  color: #4D4D4D;
  font-family: "Open Sans";
  font-size: 24px;
  letter-spacing: 0;
  line-height: 36px;
  text-align: center;
  font-weight: 300;
`;

export const NoFreeSpotsHeading = styled(NoFreeSpotsText)`
  font-weight: 600;
  margin-top: 60px;
  margin-bottom: 12px;
`;
