import styled from 'styled-components';

const breakPoint = '600px';

export const Heading = styled.h3`
  color: #4D4D4D;
  font-family: "Open Sans";
  font-size: 24px;
  letter-spacing: 0;
  line-height: 36px;
  text-align: center;
  margin: 70px 133px 59px 136px;
`;

export const Footer = styled.div`
  padding: 10px 20px;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  @media (min-width: ${breakPoint}) {
    & {
      flex-direction: row;
    }
  }
`;
