import styled from 'styled-components';

export const Container = styled.div`
  color: #3F9A0D;
  font-family: 'Nanum Pen Script', cursive;
  font-size: 36px;
  letter-spacing: 0;
  line-height: 44px;
  padding-left: 25px;
  padding-top: 36px;
  padding-bottom: 60px;
`;

export const Arrow = styled.img`
  margin-left: 12px;
`;
