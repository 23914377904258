import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Icon = styled(FontAwesomeIcon)`
  color: #A4A4A4;
  font-size: 24px;
`;

export const Button = styled.button`
  border: none;
  background: none;
  outline: none;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
`;
