import PropTypes from 'prop-types';

import { Bold } from './NavigationLabel.styles';

const NavigationLabel = ({ label }) => {
  const [month, year] = label.split(' ');
  return <span><Bold>{month}</Bold> {year}</span>;
};

NavigationLabel.propTypes = {
  label: PropTypes.string.isRequired,
};

export default NavigationLabel;
