import { combineReducers } from 'redux';

import practice from './practice';
import calendar from './calendar';
import steps from './steps';
import otp from './otp';

const rootReducer = combineReducers({
  practice,
  calendar,
  steps,
  otp,
});

export default rootReducer;
