import axios from 'axios';

import { API_URL } from '../consts/config';

import { getPracticeData } from './practice';
import { getAvailableDates } from './calendar';

export const INIT = 'INIT';
export const CALL_API = 'CALL_API';

let axiosInstance = null;

export const init = () => (dispatch) => {
  dispatch({
    type: INIT
  });

  axiosInstance = axios.create({
    baseURL: API_URL,
    timeout: 10000,
  });

  dispatch(getPracticeData());
  dispatch(getAvailableDates());
};

export const callApi = axiosRequestConfig => dispatch => {
  if (!axiosInstance) {
    return;
  }

  dispatch({
    type: CALL_API,
    axiosRequestConfig
  });

  return axiosInstance.request(axiosRequestConfig);
};
