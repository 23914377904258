import styled from 'styled-components';

const breakPoint = '600px';

export const Container = styled.div`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }
`;

export const Heading = styled.h3`
  color: #4D4D4D;
  font-family: "Helvetica Neue", "Open Sans";
  font-size: 36px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 36px;
  text-align: center;
  margin: 45px 0;
`;

export const PanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 600px;
  margin: 0 auto;

  @media (min-width: ${breakPoint}) {
    & {
      flex-direction: row;
    }
  }
`;

const Panel = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 10px;
  flex-direction: column;
`;

export const PanelLeft = styled(Panel)`
  @media (min-width: ${breakPoint}) {
    & {
      width: 40%;
    }
  }
`;

export const PanelRight = styled(Panel)`
  @media (min-width: ${breakPoint}) {
    & {
      width: 60%;
    }
  }
`;

export const Paragraph = styled.p`
  color: #757575;
  font-family: "Open Sans";
  font-size: 18px;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: 0;
  margin-bottom: 20px;
`;

export const ErrorParagraph = styled(Paragraph)`
  color: #ce71ad;
`;

export const Bold = styled.span`
  font-weight: bold;
`;

export const FieldLabel = styled.label`
  color: #A4A4A4;
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 15px;
  margin: 10px 0;
`;

export const Dot = styled.span`
  color: #F82626;
`;

export const TextButton = styled.button`
  display: inline;
  background: none;
  color: #6E418E;
  cursor: pointer;
  outline: none;
  border: none;
  font-family: inherit;
  font-size: inherit;
  font-weight: bold;
  margin: 0;
  padding: 0;
`;

export const inputStyle = {
  fontFamily: 'Open Sans',
  margin:  '4px',
  MozAppearance: 'textfield',
  width: '38px',
  height: '49px',
  borderRadius: '9px',
  fontSize: '18px',
  backgroundColor: '#fff',
  color: '#333',
  border: '2px solid #757575',
  textAlign: 'center',
  outline: 'none',
  boxSizing: 'border-box',
  marginBottom: '27px',
};

export const inputStyleInvalid = {
  ...inputStyle,
  borderColor: '#ce71ad',
};
