import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Icon, Container, Description } from './Notice.styles';

const Notice = ({ icon, children }) => {
  return (
    <Container>
      <Icon icon={icon} />
      <Description>
        {children}
      </Description>
    </Container>
  );
};

Notice.propTypes = {
  icon: FontAwesomeIcon.propTypes.icon,
  children: PropTypes.node.isRequired,
};

export default Notice;
