import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Text, Link } from './ContactUs.styles';

const ContactUs = ({ practiceEmail }) => {
  return (
    <Text>
      Didn’t find suitable time? <Link href={`mailto:${practiceEmail}`}>Contact us</Link>.
    </Text>
  );
};

ContactUs.propTypes = {
  practiceEmail: PropTypes.string.isRequired
};

export default connect(state => ({
  practiceEmail: state.practice.email,
}))(ContactUs);
