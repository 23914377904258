import { h, Component }from 'preact';
import ReactCodeInput from 'react-code-input';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ModalFooter from '../ModalFooter';
import { sendOtp, verifyOtp } from '../../actions/otp';
import Loader from '../Loader';

import {
  Container,
  Heading,
  PanelContainer,
  PanelLeft,
  PanelRight,
  Paragraph,
  Bold,
  FieldLabel,
  Dot,
  inputStyle,
  inputStyleInvalid,
  TextButton,
  ErrorParagraph,
} from './VerifyOtpStep.styles';
import messageSvg from './message.svg';

const OTP_LENGTH = 4;
const VERIFICATION_STATES = {
  INITIAL: 'INITIAL',
  LOADING: 'LOADING',
  ERROR: 'ERROR',
};

class VerifyOtpStep extends Component {
  state = {
    otp: '',
    verificationState: VERIFICATION_STATES.INITIAL,
  };

  onInputChange = otp => {
    this.setState({ otp });
    this.setVerificationState(VERIFICATION_STATES.INITIAL);
  };

  get isOtpFilled() {
    return this.state.otp.length === OTP_LENGTH;
  }

  setVerificationState = verificationState => this.setState({ verificationState });

  onNextClick = async () => {
    this.setVerificationState(VERIFICATION_STATES.LOADING);

    try {
      await this.props.verifyOtp(this.state.otp);
      // go next
      this.props.nextStep();
    } catch (e) {
      this.setVerificationState(VERIFICATION_STATES.ERROR);
    }
  };

  resetOtpInput = () => this.setState({ otp: '' });

  resendOtp = async () => {
    this.setVerificationState(VERIFICATION_STATES.LOADING);
    await this.props.sendOtp();
    this.resetOtpInput();
    this.setVerificationState(VERIFICATION_STATES.INITIAL);
  };

  render() {
    const { verificationState, otp } = this.state;

    return (
      <Container>
        <Heading>Enter the OTP you received by SMS</Heading>
        <PanelContainer>
          <PanelLeft>
            <img src={messageSvg} />
          </PanelLeft>
          <PanelRight>
            <Paragraph>Enter reservation <Bold>SMS code</Bold> we sent  on your mobile phone to proceed with booking.</Paragraph>
            <Paragraph>We know this is a hassle, but we’re doing this to make sure that <Bold>your information remains private</Bold>. Thanks for your patience!</Paragraph>

            {verificationState === VERIFICATION_STATES.LOADING && <Loader />}
            {verificationState !== VERIFICATION_STATES.LOADING && (
              <>
                <FieldLabel>
                  Enter the OTP you received by SMS <Dot>*</Dot>
                </FieldLabel>
                <ReactCodeInput
                  type='number'
                  fields={4}
                  inputStyle={inputStyle}
                  inputStyleInvalid={inputStyleInvalid}
                  onChange={this.onInputChange}
                  value={otp}
                  isValid={verificationState !== VERIFICATION_STATES.ERROR}
                />

                {verificationState === VERIFICATION_STATES.INITIAL && (
                  <Paragraph>
                    Didn’t receive a code?
                    {' '}<TextButton onClick={this.props.prevStep}>Go back</TextButton>{' '}
                    and check your mobile number, or <TextButton onClick={this.resendOtp}>try to send another code</TextButton>.
                  </Paragraph>
                )}

                {verificationState === VERIFICATION_STATES.ERROR && (
                  <>
                    <ErrorParagraph>
                      The code you entered did not match the SMS code we sent you. 
                    </ErrorParagraph>
                    <Paragraph>
                      <TextButton onClick={this.resendOtp}>Click here</TextButton>{' '}
                      to receive a new code.
                    </Paragraph>
                  </>
                )}
              </>
            )}
          </PanelRight>
        </PanelContainer>
        <ModalFooter
          isNextButtonAvailable={this.isOtpFilled && verificationState !== VERIFICATION_STATES.LOADING}
          onNextButtonClick={this.onNextClick}
          onBackButtonClick={this.props.prevStep}
        />
      </Container>
    );
  };
};

VerifyOtpStep.propTypes = {
  prevStep: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  sendOtp: PropTypes.func.isRequired,
  verifyOtp: PropTypes.func.isRequired,
};

export default connect(null, {
  sendOtp,
  verifyOtp, 
})(VerifyOtpStep);
