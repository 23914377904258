import { SET_CURRENT_STEP, GO_TO_PREV_STEP } from '../actions/steps';

const initialState = {
  currentStep: null,
  prevStep: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_STEP:
      return {
        ...state,
        currentStep: action.payload,
        prevStep: state.currentStep,
      };
    case GO_TO_PREV_STEP: {
      return {
        ...state,
        currentStep: state.prevStep,
        prevStep: state.currentStep,
      };
    }
    default:
      return state;
  }
};
