import { Container, Arrow } from './CallToAction.styles';
import arrow from './arrow.svg';

const CallToAction = () =>{
  return (
    <Container>
      Choose a day <br />
      to start
      <Arrow src={arrow} />
    </Container>
  );
};

export default CallToAction;
