import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import SendOtpStep from '../SendOtpStep';
import QuitBookingStep from '../QuitBookingStep';
import VerifyOtpStep from '../VerifyOtpStep';
import SelectTimeSlotStep from '../SelectTimeSlotStep';
import { setCurrentStep } from '../../actions/steps';
import { stepIds } from '../../consts/steps';

import { modalStyles } from './StepsModal.styles';
import CloseButton from './components/CloseButton';

const steps = [
  {
    component: SendOtpStep,
    id: stepIds.SEND_OTP,
  },
  {
    component: VerifyOtpStep,
    id: stepIds.VERIFY_OTP,
  },
  {
    component: SelectTimeSlotStep,
    id: stepIds.SELECT_TIME_SLOT,
  },
  {
    component: () => <h3>Who is the patient?</h3>,
    id: stepIds.PATIENT_DETAILS,
  },
];

// steps that cannot be accessed with prev / next navigation
const hiddenSteps = [
  {
    component: QuitBookingStep,
    id: stepIds.QUIT_BOOKING,
  },
];

const getCurrentStep = stepId => [...steps, ...hiddenSteps].find(s => s.id === stepId);

const StepsModal = ({ isOpen, currentStepId, setCurrentStep }) => {
  const currentStep = getCurrentStep(currentStepId);
  const Component = currentStep ? currentStep.component : null;

  const currentIndex = steps.indexOf(currentStep);
  const isHiddenStep = currentIndex === -1;
  const isLast = currentIndex === steps.length - 1;
  const isFirst = currentIndex === 0;
  const nextStep = !isLast && !isHiddenStep ? () => {
    setCurrentStep(steps[currentIndex + 1].id);
  } : null;
  const prevStep = !isFirst && !isHiddenStep ? () => {
    setCurrentStep(steps[currentIndex - 1].id);
  } : null;

  return (
    <Modal
      isOpen={isOpen}
      style={modalStyles}
      contentLabel="Booking"
    >
      <Component nextStep={nextStep} prevStep={prevStep} />
      <CloseButton currentStepId={currentStepId} />
    </Modal>
  );
};

StepsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  currentStepId: PropTypes.string,
  setCurrentStep: PropTypes.func.isRequired,
};

export default connect(state => ({
  isOpen: !!state.steps.currentStep,
  currentStepId: state.steps.currentStep,
}), {
  setCurrentStep,
})(StepsModal);
