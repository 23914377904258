import styled from 'styled-components';

export const Container = styled.div`
  .react-calendar__navigation {
    border-bottom: 3px solid #e7e7e7;
    padding-bottom: 20px;
  }

  .react-calendar__navigation__prev2-button {
    display: none;
  }

  .react-calendar__navigation__next2-button {
    display: none;
  }

  .react-calendar__navigation__prev-button,
  .react-calendar__navigation__next-button {
    background: rgba(0,0,0,0.15);
    height: 47px;
    width: 47px;
    border-radius: 100%;
    border: none;
    color: #6E418E;
    font-size: 20px;
    outline: none;

    :not(:disabled) {
      cursor: pointer;
    }

    :disabled {
      background-color: #e7e7e7;
      color: #CCCCCC;
    }
  }

  .react-calendar__navigation__label {
    border: none;
    pointer-events: none;
    background: none;
  }

  .react-calendar__navigation__label__labelText {
    color: #4D4D4D;
    font-family: "Open Sans";
    font-size: 24px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 33px;
    text-align: center;
  }

  .react-calendar__month-view {
    height: 480px;
  }

  .react-calendar__month-view__weekdays {
    padding: 20px 0;
  }

  .react-calendar__month-view__weekdays__weekday {
    color: #4D4D4D;
    font-family: "Open Sans";
    font-size: 18px;
    font-weight: 300;
    letter-spacing: 0;
    text-align: center;
    text-transform: uppercase;

    abbr {
      text-decoration: none;
    }
  }

  .react-calendar__tile {
    height: 67.97px;
    width: 68.14px;
    border: 1px solid #FFFFFF;
    background-color: #dff7d4;
    padding: 0;
    color: #3F9A0D;
    font-family: "Open Sans";
    font-size: 18px;
    text-align: center;

    &.react-calendar__tile--active {
      outline: none;
      background: #3F9A0D;
      color: #FFFFFF;
    }

    abbr {
      display: none;
    }

    :not(:disabled) {
      cursor: pointer;
    }

    :disabled {
      background-color: #e7e7e7;
      color: #CCCCCC;
    }
  }

  .__react_component_tooltip {
    color: #FFFFFF;
    font-family: "Open Sans";
    font-size: 12px;
    text-align: center;
  }
`;
