import { callApi } from './app';

export const SEND_OTP = 'SEND_OTP';
export const SEND_OTP_SUCCESS = 'SEND_OTP_SUCCESS';
export const SEND_OTP_FAILURE = 'SEND_OTP_FAILURE';

export const VERIFY_OTP = 'VERIFY_OTP';
export const VERIFY_OTP_SUCCESS = 'VERIFY_OTP_SUCCESS';
export const VERIFY_OTP_FAILURE = 'VERIFY_OTP_FAILURE';

export const sendOtp = phoneNumber => async (dispatch, getState) => {
  const receiverNumber = phoneNumber || getState().otp.phoneNumber;

  dispatch({
    type: SEND_OTP,
    payload: receiverNumber,
  });

  try {
    await dispatch(
      callApi({
        url: '/auth/otp',
        method: 'POST',
        data: {
          phoneNumber,
        },
      }),
    );

    dispatch({
      type: SEND_OTP_SUCCESS,
    });
  } catch (e){
    dispatch({
      type: SEND_OTP_FAILURE,
      payload: e,
    });

    throw e;
  }
};

export const verifyOtp = otp => async (dispatch, getState) => {
  const phoneNumber = getState().otp.phoneNumber;

  dispatch({
    type: VERIFY_OTP,
    payload: { otp, phoneNumber },
  });

  try {
    await dispatch(
      callApi({
        url: '/auth/otp-session',
        method: 'POST',
        data: {
          otp,
          phoneNumber,
        },
      }),
    );

    dispatch({
      type: VERIFY_OTP_SUCCESS,
    });
  } catch (e){
    dispatch({
      type: VERIFY_OTP_FAILURE,
      payload: e,
    });

    throw e;
  }
};
