import { Component } from 'preact';
import PropTypes from 'prop-types';
import moment from 'moment';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import { Button, Icon } from './TimeSlot.styles';

class TimeSlot extends Component {
  onClick = () => {
    const { timeSlot } = this.props;
    this.props.onClick(timeSlot);
  };

  render() {
    const { timeSlot, active } = this.props;

    if (!timeSlot) {
      return null;
    }

    const [hour, minute] = timeSlot.split(':');
    const formattedTimeSlot = moment()
      .set('h', hour)
      .set('m', minute)
      .set('ms', 0)
      .format('LT');

    return (
      <Button active={active} onClick={this.onClick}>
        {!!active && <Icon icon={faCheck} />}
        {formattedTimeSlot}
      </Button>
    );
  }
};

TimeSlot.propTypes = {
  timeSlot: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
};

export default TimeSlot;
