import styled from 'styled-components';

const breakPoint = '600px';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${breakPoint}) {
    & {
      flex-direction: row;
    }
  }
`;

export const Panel = styled.div`
  @media (min-width: ${breakPoint}) {
    & {
      width: 50%;
    }
  }

  width: 100%;
  padding: 30px;
`;

export const Heading = styled.h3`
  color: #4D4D4D;
  font-family: "Helvetica Neue", "Open Sans";
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 36px;
  margin-top: 0;
`;

export const Description = styled.div`
  color: #757575;
  font-family: "Open Sans";
  font-size: 18px;
  letter-spacing: 0;
  line-height: 24px;
  padding-bottom: 18px;
`;

export const Bold = styled.span`
  font-weight: bold;
`;
