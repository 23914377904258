import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { setCurrentStep, goToPrevStep } from '../../actions/steps';
import CancelButton from '../ModalFooter/components/CancelButton';
import ConfirmButton from '../ModalFooter/components/ConfirmButton';

import { Heading, Footer } from './QuitBookingStep.styles';

const QuitBookingStep = ({ setCurrentStep, goToPrevStep }) => {
  const onConfirm = () => {
    setCurrentStep(null);
  };

  const onDecline = () => {
    goToPrevStep();
  };

  return (
    <div>
      <Heading>
        You&apos;re going to leave without booking an appointment. Are you sure?
      </Heading>
        
      <Footer>
        <CancelButton label='NO, STAY' onClick={onDecline} />
        <ConfirmButton label='YES, LEAVE' onClick={onConfirm} />
      </Footer>
    </div>
  );
};

QuitBookingStep.propTypes = {
  setCurrentStep: PropTypes.func.isRequired,
  goToPrevStep: PropTypes.func.isRequired,
};

export default connect(null, {
  setCurrentStep,
  goToPrevStep,
})(QuitBookingStep);
